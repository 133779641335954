<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-if="show">
          <b-form-group
            id="input-group-title"
            label="Title"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="item.title"
              type="text"
              required
              placeholder="Enter title"
            ></b-form-input>
          </b-form-group>

          <div v-if="!item.signMatch">
            <b-form-group
              id="textarea-group-content"
              label="Content"
              label-for="textarea-content"
            >
              <b-form-textarea
                id="'textarea-content"
                v-model="item.content"
                placeholder="Enter content"
                rows="3"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              id="input-group-imageUrl"
              label="Navigation Type"
              label-for="input-imageUrl"
              style="max-width: 200px;"
            >
              <b-form-input
                id="input-imageUrl"
                v-model="item.imageUrl"
                type="text"
                placeholder="Enter Image Url"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-navigationType"
              label="Navigation Type"
              label-for="input-navigationType"
              style="max-width: 200px;"
            >
              <b-form-input
                id="input-navigationType"
                v-model="item.navigationType"
                type="text"
                placeholder="Enter Navigation Type"
              ></b-form-input>
            </b-form-group>
          </div>

          <div v-if="item.signMatch">
            <div
              class="text-center d-flex justify-content-between"
              v-if="isSignMatchLoading"
            >
              <div class="text-center">
                <b-spinner variant="primary" label="Text Centered"></b-spinner>
              </div>
            </div>

            <b-list-group
              v-for="(signMatch, signMatchIndex) in signMatches"
              :key="signMatchIndex"
            >
              <b-list-group-item
                v-b-toggle="'tab-' + signMatchIndex"
                class="m-1"
                v-if="signMatch.horoscope !== null"
                >{{ signMatch.horoscope || "Horoscope" }}</b-list-group-item
              >
              <b-collapse
                :visible="signMatch.horoscope === null"
                :id="'tab-' + signMatchIndex"
              >
                <b-card>
                  <b-form-group role="tablist">
                    <b-card
                      no-body
                      class="mb-1"
                      v-for="(horoscope, horoscopeIndex) in horoscopes"
                      :key="horoscopeIndex"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle="'accordion-' + horoscope"
                          variant="info"
                          >{{ horoscope }}</b-button
                        >
                      </b-card-header>
                      <b-collapse
                        :id="'accordion-' + horoscope"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-form-textarea
                            :id="'textarea-' + horoscope"
                            v-model="signMatch[horoscope]"
                            :placeholder="horoscope"
                            rows="3"
                            max-rows="8"
                          ></b-form-textarea>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </b-form-group>
                  <div class="form-submit">
                    <b-button
                      type="submit"
                      variant="primary"
                      v-on:click="saveSighMatch(signMatchIndex, $event)"
                    >
                      <b-spinner small v-if="isSignMatchSubmiting"></b-spinner>
                      Save Sign Match
                    </b-button>
                  </div>
                </b-card>
              </b-collapse>
            </b-list-group>
          </div>

          <b-form-group
            id="input-group-order"
            label="Order"
            label-for="input-order"
            style="max-width: 75px;"
          >
            <b-form-input
              id="input-order"
              v-model="item.order"
              type="number"
              required
              placeholder="Enter order"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              id="checkbox-locked"
              v-model="item.locked"
              name="locked"
              value="true"
              unchecked-value="false"
              >Locked</b-form-checkbox
            >
          </b-form-group>

          <div class="form-submit">
            <b-button type="submit" variant="secondary" v-on:click="savePost">
              <b-spinner small v-if="isLoading"></b-spinner>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { XmlEntities } from "html-entities";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
const entities = new XmlEntities();

export default {
  data() {
    return {
      isLoading: false,
      item: {},
      signMatches: [],
      isSignMatchLoading: false,
      isSignMatchSubmiting: false,
      show: true,
      id: null,
      isEditMode: false,
      horoscopes: [
        "aries",
        "taurus",
        "gemini",
        "cancer",
        "leo",
        "virgo",
        "libra",
        "scorpio",
        "sagittarius",
        "capricorn",
        "aquarius",
        "pisces"
      ]
    };
  },
  methods: {
    savePost(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        ApiService.post("posts?id=" + this.id, this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Post has been updated sucessfully!", {
                title: "Success",
                variant: "success"
              });
            } else {
              this.$bvToast.toast("Something went wrong while saving post...", {
                title: "Error",
                variant: "danger"
              });
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast("Something went wrong while saving post...", {
              title: "Error",
              variant: "danger"
            });
          });
      });
    },
    saveSighMatch(index, evt) {
      evt.preventDefault();
      this.isSignMatchSubmiting = true;
      const item = this.signMatches[index];
      return new Promise(resolve => {
        ApiService.post("signMatch?id=" + item.id, item)
          .then(({ data }) => {
            this.isSignMatchSubmiting = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Sign Match has been updated sucessfully!", {
                title: "Success",
                variant: "success"
              });
            } else {
              this.$bvToast.toast(
                "Something went wrong while saving sign match...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(
              "Something went wrong while saving sign match...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getSignMatch: function(postId) {
      this.isSignMatchLoading = true;
      return new Promise(resolve => {
        ApiService.get("posts", { type: "SignMatches", postId: postId })
          .then(({ data }) => {
            this.isSignMatchLoading = false;
            let items =
              data.response && data.response.items.length > 0
                ? data.response.items
                : null;
            if (items) {
              items.forEach(item => {
                Object.keys(item).forEach(prop => {
                  if (typeof item[prop] == "string") {
                    item[prop] = entities.decode(item[prop]);
                  }
                });
              });
              this.isSignMatchLoading = false;
              this.signMatches = items;
            } else {
              this.signMatches = [];
            }
            resolve();
          })
          .catch(() => {
            this.$bvToast.toast(
              "Something went wrong while retrieving data from external server...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getItem: function() {
      this.id = this.$route.params.id || this.item.id || this.$route.query.id;
      this.isEditMode = !!this.id;
      if (this.isEditMode) {
        return new Promise(resolve => {
          ApiService.get("posts", { id: this.id })
            .then(({ data }) => {
              let item =
                data.response && data.response.items.length > 0
                  ? data.response.items[0]
                  : null;
              if (item) {
                item.content = entities.decode(item.content);
                this.item = item;
                if (item.signMatch) {
                  return this.getSignMatch(item.id);
                }
              } else {
                this.item = {};
                this.isEditMode = false;
              }
              resolve();
            })
            .catch(() => {
              this.$bvToast.toast(
                "Something went wrong while retrieving data from external server...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            });
        });
      } else {
        location = "/posts";
      }
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Posts", route: "posts" },
      { title: this.isEditMode ? "Edit Post" : "Add Post" }
    ]);
  },
  computed: {}
};
</script>
